<template>
	<section class="col-12">
		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.insurance') }}</b>
				</h3>
			</div>
		</div>
		<!-- <Field>
			<template slot="title">{{ $t('companyInsurance.tillagslon') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.supplementarySalary.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.supplementarySalary.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="insurance.supplementarySalary.value" :disabled="disabled" />
			</template>
		</Field> -->

		<Field>
			<template slot="title">
				{{ $t('global.startDate') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.startDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.startDate.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDate v-model="insurance.startDate.value" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.expirationDate') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.expirationDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.expirationDate.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown v-model="insurance.expirationDate.value" :options="['65', '67', 'Folkepension']" :disabled="disabled" :placeholder="'Udløbsdato'" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.waitingPeriode') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.waitingPeriode.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.waitingPeriode.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown
					v-model="insurance.waitingPeriode.value"
					:options="['Straks', '3 måneder', '6 måneder', '9 måneder']"
					:disabled="disabled"
					:placeholder="'Periode'"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.drawingWindow') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.drawingWindow.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.drawingWindow.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown v-model="insurance.drawingWindow.value" :options="['3 måneder', '6 måneder']" :disabled="disabled" :placeholder="'Periode'" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.tabarbejdsevnepct') }}</template>
			<template slot="taxCode">
				<TaxCodes
					v-model="insurance.workAbility.taxCode"
					:showTax="true"
					:tax="[
						{ code: '1', taxFree: false },
						{ code: '7', taxFree: true },
					]"
					:disabled="disabled"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.workAbility.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.workAbility.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="insurance.workAbility.isSalaryScale" :disabled="disabled" :text="$t('global.salaryScale')" />
				<InputNumber
					v-model="insurance.workAbility.value"
					:min="insurance.workAbility.coverageSpanMin"
					:max="insurance.workAbility.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="insurance.workAbility.isSalaryScale || disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.workAbility.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.workAbility.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>

				<InputDropdownValuta class="mt-1" v-model="insurance.workAbility.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.tabarbejdsevneudb') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.workAbilityPayment.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.workAbilityPayment.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.workAbilityPayment.value"
					:options="[
						{ value: 'standard', text: $t('global.paymentEmployer') },
						{ value: 'expanded', text: $t('global.paymentEmployee') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.pensionbidragpct') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.pensionContribution.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.pensionContribution.comment" :disabled="disabled" />
			</template>
			<template slot="taxCode">
				<TaxCodes
					v-model="insurance.pensionContribution.taxCode"
					:showTax="true"
					:tax="[
						{ code: '1', taxFree: false },
						{ code: '7', taxFree: true },
					]"
					:disabled="disabled"
				/>
			</template>
			<template slot="comp">
				<InputCheck
					v-model="insurance.pensionContribution.sameAsMandatoryContribution"
					:disabled="disabled"
					:text="$t('global.sameAsMandatoryContribution')"
					@change.native="$emit('pensionContributionChanged')"
				/>
				<InputNumber
					v-model="insurance.pensionContribution.value"
					:min="insurance.pensionContribution.coverageSpanMin"
					:max="insurance.pensionContribution.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="insurance.pensionContribution.sameAsMandatoryContribution || disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.pensionContribution.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.pensionContribution.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.pensionContribution.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.invalidesum') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.invalideSum.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.invalideSum.comment" :disabled="disabled" />
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					v-model="insurance.invalideSum.taxCode"
					:disabled="disabled"
					:text="$t('global.taxCodes.taxCode5') + ' ' + $t('global.taxFree')"
					:code="5"
				/>
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.invalideSum.value"
					:min="insurance.invalideSum.coverageSpanMin"
					:max="insurance.invalideSum.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.invalideSum.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.invalideSum.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.invalideSum.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.dodfaldssum') }}
			</template>

			<template slot="taxCode">
				<TaxCodes
					:disabled="disabled"
					v-model="insurance.deathSum.taxCode"
					:showTax="true"
					:tax="[
						{ code: '2', taxFree: false },
						{ code: '5', taxFree: true },
					]"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.deathSum.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.deathSum.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.deathSum.value"
					:min="insurance.deathSum.coverageSpanMin"
					:max="insurance.deathSum.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.deathSum.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.deathSum.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.deathSum.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.bornepension') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					v-model="insurance.childPension.taxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:disabled="disabled"
					:code="5"
				/>
			</template>
			<template slot="ageGroup">
				<InputDropdown
					v-model="insurance.childPension.ageGroup"
					:options="['Under 18 år', 'Under 21 år', 'Under 24 år']"
					:disabled="disabled"
					:placeholder="'Aldersgruppe'"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.childPension.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.childPension.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.childPension.value"
					:min="insurance.childPension.coverageSpanMin"
					:max="insurance.childPension.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.childPension.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.childPension.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.childPension.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.kritisksygdom') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					:disabled="disabled"
					v-model="insurance.criticalDisease.taxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:code="5"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.criticalDisease.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.criticalDisease.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.criticalDisease.value"
					:min="insurance.criticalDisease.coverageSpanMin"
					:max="insurance.criticalDisease.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDisease.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDisease.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.criticalDisease.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.kritisksygdomborn') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					v-model="insurance.criticalDiseaseChild.taxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:disabled="disabled"
					:code="5"
				/>
			</template>
			<template slot="ageGroup">
				<InputDropdown
					v-model="insurance.criticalDiseaseChild.ageGroup"
					:options="['Under 18 år', 'Under 21 år', 'Under 24 år']"
					:disabled="disabled"
					:placeholder="'Aldersgruppe'"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.criticalDiseaseChild.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.criticalDiseaseChild.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.criticalDiseaseChild.value"
					:min="insurance.criticalDiseaseChild.coverageSpanMin"
					:max="insurance.criticalDiseaseChild.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDiseaseChild.coverageSpanMin" :limit="$t('global.min')" :disabled="disabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDiseaseChild.coverageSpanMax" :limit="$t('global.max')" :disabled="disabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.criticalDiseaseChild.type" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">Betaling via faktura eller integreteret pension</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurancePayment.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsurancePayment.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.healthInsurancePayment.value"
					:options="[
						{ value: 'invoice', text: 'Faktura' },
						{ value: 'pension', text: 'Pension' },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>
		<b-button @click.prevent="addHealthInsurance" v-show="showAddInsurance" class="btn-add" type="button" :disabled="disabled">
			Tilføj sundhedsforsikring tilvalg
		</b-button>
		<Field v-for="(healthInsurance, index) in insurance.healthInsurances" :key="index">
			<template slot="title" v-if="!supplierInsuranceTemplate"
				>{{ healthInsurance.title }}
				<b-button v-show="showAddInsurance" :disabled="disabled" @click.prevent="deleteHealthInsurance(index)" type="button"> Slet denne tilvalg </b-button>
				<b-button v-show="showAddInsurance" :disabled="disabled" @click.prevent="renameHealthInsurance(index, healthInsurance.title)" type="button">
					Omdøb denne tilvalg
				</b-button>
			</template>
			<template slot="title" v-if="supplierInsuranceTemplate">
				{{ supplierInsuranceTemplate.healthInsurances[index].title }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurances[index].comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="healthInsurance.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="healthInsurance.value" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikring') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurance.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsurance.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.healthInsurance.insuranceType"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
				<InputDate
					v-model="insurance.healthInsurance.date"
					:options="[
						{ value: 'standard', text: $t('global.paymentEmployer') },
						{ value: 'expanded', text: $t('global.paymentEmployee') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>
		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikringborn') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsuranceChild.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsuranceChild.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.healthInsuranceChild.insuranceType"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikringmedforsikrede') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurancePartner.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsurancePartner.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.healthInsurancePartner.value"
					:options="[
						{ value: true, text: $t('global.yes') },
						{ value: false, text: $t('global.no') },
					]"
					:disabled="disabled"
				/>
				<InputRadio
					v-model="insurance.healthInsurancePartner.insuranceType"
					v-if="insurance.healthInsurancePartner.value == true"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>
	</section>
</template>

<script>
import Field from '@/modules/global/atomComponents/Field'
import Comment from '@/modules/global/atomComponents/InputComment'
import TaxCodes from '@/modules/global/atomComponents/InputTaxCode.vue'
import TaxCodeCheck from '@/modules/global/atomComponents/InputTaxCodeCheck.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputRadio from '@/modules/global/atomComponents/InputRadio.vue'
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'
import InputMinMax from '@/modules/global/atomComponents/InputMinMax.vue'
import InputDropdownValuta from '@/modules/global/atomComponents/InputDropdownValuta.vue'

export default {
	name: 'CompanyInsurance',
	components: {
		Field,
		InputNumber,
		InputRadio,
		InputDate,
		TaxCodes,
		TaxCodeCheck,
		Comment,
		InputDropdown,
		InputCheck,
		InputMinMax,
		InputDropdownValuta,
	},
	props: {
		value: Object,
		insuranceIndex: Number,
		disabled: Boolean,
		showAddInsurance: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		addHealthInsurance() {
			this.$swal
				.fire({
					title: 'Indsæt navn for tilvalg for sundhedsforsikring',
					input: 'text',
					inputValidator: (value) => {
						if (!value) {
							return 'En tilvalg skal have et navn'
						}
					},
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja opret!',
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.insurance.addHealthInsurance(result.value)
					}
				})
		},
		renameHealthInsurance(index, title) {
			this.$swal
				.fire({
					title: 'Indsæt navn for tilvalg for sundhedsforsikring',
					input: 'text',
					inputValue: title,
					inputValidator: (value) => {
						if (!value) {
							return 'En tilvalg skal have et navn'
						}
					},
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja opret!',
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.insurance.healthInsurances[index].title = result.value
					}
				})
		},
		deleteHealthInsurance(index) {
			this.insurance.healthInsurances.splice(index, 1)
		},
	},
	computed: {
		supplierInsuranceTemplate() {
			if (this.insurance.supplierTemplateId) {
				var suppliers = this.$store.getters['supplier/suppliers']
				var supplierId = this.insurance.supplierId
				var supplier = suppliers.find((supplier) => supplier.id == supplierId)
				var insurance = supplier.groupTemplates.find((template) => template.id == this.insurance.supplierTemplateId).insurance
				return insurance
			} else {
				return null
			}
		},
		insurance: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
